import * as React from 'react';
import GoldArrow from '../images/gold-arrow.svg';

const ButtonBlackGoldComponent = ({ title, subtitle }) => (
  <button className="w-full relative group text-left border border-yellow-700 lg:border-transparent hover:border-yellow-700 transition-colors duration-200 py-4">
    <div className="pl-6 lg:pl-0 lg:group-hover:translate-x-6 lg:transition-transform lg:ease-in-out lg:duration-300">
      <div className="henderson-font text-xs text-black font-semibold mb-2">{title}</div>
      <div className="text-2xl text-yellow-700">{subtitle}</div>
    </div>
    <GoldArrow className="absolute right-6 top-1/2 -translate-y-1/2" />
  </button>
);

export default ButtonBlackGoldComponent;
